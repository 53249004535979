import React from 'react';

export const ArrowIcon = ({ className, direction, ...props }) => (
  <svg
    className={`arrow-${direction} ${className || ''}`}
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill-current"
      d="M8 19L16 11.0583L15.0001 10.0656L8.7072 16.3126L8.7072 -3.18778e-07L7.29299 -3.80596e-07L7.29299 16.313L0.999842 10.0657L1.42398e-06 11.0583L8 19Z"
    />
  </svg>
);

export const CheckIcon = (props) => (
  <svg {...props} width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill-current"
      d="M45.6274 17.3434L39.9706 11.6865L17.3432 34.3139L6.02944 23.0002L0.37259 28.6571L17.3432 45.6276L45.6274 17.3434Z"
    />
  </svg>
);

export const StarIcon = ({ filled, className, hoverFill, ...props }) => (
  <svg
    className={`text-${filled ? 'primary' : 'transparent'} ${hoverFill ? 'hover:text-primary' : ''} ${className}`}
    {...props}
    viewBox="0 0 363.66 364.49"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      strokeWidth="16"
      stroke="#FE2D29"
      className="fill-current"
      points="294.83 350.64 248.68 213.83 348.83 137.64 222.98 137.64 181.83 15.64 140.68 137.64 14.83 137.64 114.98 213.83 68.83 350.64 181.83 264.68 294.83 350.64"
    />
  </svg>
);

export const ContactIcon = (props) => (
  <svg viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      className="fill-current"
      d="M24 2.5C24 1.125 22.92 0 21.6 0H2.4C1.08 0 0 1.125 0 2.5V17.5C0 18.875 1.08 20 2.4 20H21.6C22.92 20 24 18.875 24 17.5V2.5ZM21.6 2.5L12 8.75L2.4 2.5H21.6ZM21.6 17.5H2.4V5L12 11.25L21.6 5V17.5Z"
    />
  </svg>
);

export const LogoIcon = ({ logoColor = 'black', ...props }) => (
  <svg {...props} height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3452 500.46">
    <path className={`fillCurrent text-${logoColor}`} d="M268.78,492.45H129V161.54H0V42H397.75V161.54h-129Z" />
    <path
      className={`fillCurrent text-${logoColor}`}
      d="M1724.58,498H1557L1481,366,1404.37,498H1241.54l151.89-237.4L1242.93,41.68h169.66l69.77,124.5,72.54-124.5h166.93L1569.94,259.91Z"
    />
    <path
      className={`fillCurrent text-${logoColor}`}
      d="M2200.61,498H2033L1957,366,1880.4,498H1717.56l151.89-237.4L1719,41.68h169.66l69.78,124.5,72.53-124.5h166.93L2046,259.91Z"
    />
    <path
      className={`fillCurrent text-${logoColor}`}
      d="M2316.41,498,2192.57,41.68h139.58l43.79,213.44c3.42,14.37,8.21,46.55,10.25,66.38,1.36-18.47,6.15-47.22,10.28-66.38l43.78-213.44H2563.4l44.45,211.41s1.39,10.25,3.43,19.13c.69,4.79,1.36,10.28,2.75,16.44,2.73,16.4,2.73,17.77,4.1,32.84,2.06-15.07,2.06-15.07,4.79-32.84a102.13,102.13,0,0,0,2.73-16.44c2.06-8.88,3.43-18.46,3.43-19.13L2671.5,41.68h138.18L2692,498H2553.81l-38.3-197c-4.79-24-9.58-58.16-12.33-87.57A703.25,703.25,0,0,1,2489.5,301l-38.3,197Z"
    />
    <path className={`fillCurrent text-${logoColor}`} d="M2992.2,498H2850.59V41.68H2992.2Z" />
    <path
      className={`fillCurrent text-${logoColor}`}
      d="M3444.48,162.76,3224.85,376.9H3452V498H3045.61V383.75l217.54-221h-210V41.68h391.35Z"
    />
    <path
      className={`fillCurrent text-${logoColor}`}
      d="M1166.9,36h-7c-11.06,0-20.28,8.19-21,18.64a20.05,20.05,0,0,0,12.5,19.85L1141,178.75l-42.16-13.07V48a4,4,0,0,0-3.8-4c-1,0-100.2-5-197.9-7.06A101.93,101.93,0,0,0,740,37c-97.71,2.09-196.9,7-197.9,7.06a4,4,0,0,0-3.81,4V165.68l-42.16,13.07L485.74,74.62a20.13,20.13,0,0,0,12.49-20c-.71-10.45-9.92-18.64-21-18.64h-7a12,12,0,0,0-12,12V344.32a4,4,0,0,0,2.6,3.75l77.47,23.05v93.31a4,4,0,0,0,4,4h30.62l38.94,31.16a4,4,0,0,0,2.5.87H774.53a4,4,0,0,0,3.2-1.6l22.83-30.43h36l22.82,30.43a4,4,0,0,0,3.2,1.6h160.15a4,4,0,0,0,2.5-.87l38.94-31.16h30.63a4,4,0,0,0,4-4V371.12l77.48-23.05a4,4,0,0,0,2.59-3.75V48A12,12,0,0,0,1166.9,36Zm-140.14,79.73V373.07c-15.29,3-86.21,15.29-208.19,15.29S625.67,376,610.38,373.07V115.76c17-1.52,93.29-7.66,208.19-7.66S1009.74,114.24,1026.76,115.76Z"
    />
    <polygon
      className={`fillCurrent text-${logoColor}`}
      points="748.51 148.14 748.51 140.13 732.49 140.13 716.48 140.13 716.48 148.14 700.46 148.14 700.46 164.15 716.48 164.15 716.48 156.14 732.49 156.14 748.51 156.14 748.51 164.15 764.52 164.15 764.52 148.14 748.51 148.14"
    />
    <rect className={`fillCurrent text-${logoColor}`} x="712.47" y="188.17" width="32.03" height="80.07" />
    <rect className={`fillCurrent text-${logoColor}`} x="888.64" y="188.17" width="32.03" height="80.07" />
    <polygon
      className={`fillCurrent text-${logoColor}`}
      points="920.67 148.14 920.67 140.13 904.65 140.13 888.64 140.13 888.64 148.14 872.62 148.14 872.62 164.15 888.64 164.15 888.64 156.14 904.65 156.14 920.67 156.14 920.67 164.15 936.68 164.15 936.68 148.14 920.67 148.14"
    />
    <polygon
      className={`fillCurrent text-${logoColor}`}
      points="864.62 308.28 848.6 308.28 832.59 308.28 816.57 308.28 800.56 308.28 784.54 308.28 768.53 308.28 752.51 308.28 752.51 324.3 768.53 324.3 768.53 340.31 784.54 340.31 800.56 340.31 800.56 348.32 816.57 348.32 832.59 348.32 832.59 340.31 848.6 340.31 864.62 340.31 864.62 324.3 880.63 324.3 880.63 308.28 864.62 308.28"
    />
  </svg>
);

export const ExternalIcon = (props) => (
  <svg {...props} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill-current text-white"
      d="M10.0833 10.0833H1.91667V1.91667H6V0.75H1.91667C1.26917 0.75 0.75 1.275 0.75 1.91667V10.0833C0.75 10.725 1.26917 11.25 1.91667 11.25H10.0833C10.725 11.25 11.25 10.725 11.25 10.0833V6H10.0833V10.0833ZM7.16667 0.75V1.91667H9.26083L3.52667 7.65083L4.34917 8.47333L10.0833 2.73917V4.83333H11.25V0.75H7.16667Z"
    />
  </svg>
);

export const YoutubeIcon = (props) => (
  <svg {...props} viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill-current"
      d="M11.3928 6.96143H2.60753C1.21109 6.96143 0.0791016 8.09455 0.0791016 9.4907V11.523C0.0791016 12.9191 1.21109 14.052 2.60753 14.052H11.3928C12.789 14.052 13.9212 12.9191 13.9212 11.523V9.4907C13.9212 8.09455 12.789 6.96143 11.3928 6.96143ZM4.45326 8.54356H3.63651V12.6019H2.84741V8.54356H2.03123V7.85325H4.45326V8.54356ZM6.76042 12.6019H6.06051V12.2181C5.93041 12.36 5.79635 12.4684 5.65637 12.5415C5.51639 12.6177 5.3812 12.6533 5.24969 12.6533C5.08713 12.6533 4.96634 12.6016 4.88365 12.4966C4.80236 12.3919 4.76088 12.2367 4.76088 12.027V9.09841H5.46079V11.786C5.46079 11.869 5.47631 11.9285 5.50425 11.9655C5.53445 12.0036 5.58243 12.0211 5.64818 12.0211C5.69898 12.0211 5.76446 11.9968 5.8432 11.9477C5.9225 11.898 5.99475 11.836 6.06051 11.7592V9.09813H6.76042V12.6019ZM9.30155 11.8786C9.30155 12.1261 9.24651 12.3163 9.13673 12.4489C9.02751 12.5816 8.86777 12.6468 8.6609 12.6468C8.52318 12.6468 8.40069 12.6219 8.29429 12.5706C8.18733 12.5198 8.08658 12.4416 7.99486 12.3332V12.6016H7.28648V7.85325H7.99486V9.38261C8.08968 9.27677 8.18987 9.19465 8.29458 9.13961C8.40154 9.08345 8.50907 9.05636 8.618 9.05636C8.83955 9.05636 9.00832 9.1303 9.12516 9.2796C9.24256 9.42889 9.30098 9.64761 9.30098 9.93435V11.8786H9.30155ZM11.7295 10.9345H10.3901V11.5947C10.3901 11.7787 10.4127 11.9068 10.4584 11.9782C10.5052 12.0499 10.584 12.0849 10.696 12.0849C10.8123 12.0849 10.8927 12.0547 10.9396 11.9943C10.9847 11.9333 11.009 11.8007 11.009 11.5947V11.4352H11.7295V11.615C11.7295 11.974 11.6423 12.2446 11.4653 12.4272C11.2912 12.6081 11.0287 12.6979 10.6799 12.6979C10.3658 12.6979 10.1183 12.6022 9.93852 12.4097C9.75931 12.2184 9.66787 11.9528 9.66787 11.6153V10.0416C9.66787 9.73877 9.76777 9.48957 9.96618 9.29851C10.164 9.10744 10.4189 9.01205 10.7324 9.01205C11.0533 9.01205 11.2997 9.10038 11.4715 9.27762C11.6434 9.45514 11.7295 9.70914 11.7295 10.0413V10.9345Z"
    />
    <path
      className="fill-current"
      d="M10.7028 9.62451C10.5911 9.62451 10.5115 9.65584 10.4629 9.72131C10.4133 9.78284 10.3901 9.88811 10.3901 10.0351V10.3893H11.009V10.0351C11.009 9.88811 10.9842 9.78256 10.9351 9.72131C10.888 9.65612 10.8092 9.62451 10.7028 9.62451Z"
    />
    <path
      className="fill-current"
      d="M8.28778 9.61279C8.23811 9.61279 8.18787 9.6238 8.13961 9.64666C8.09051 9.66895 8.04253 9.70593 7.99512 9.7539V11.9363C8.05128 11.9928 8.10688 12.0362 8.16106 12.0611C8.21497 12.0851 8.27085 12.0986 8.3304 12.0986C8.41648 12.0986 8.47913 12.0738 8.51949 12.0235C8.55928 11.9739 8.5796 11.8946 8.5796 11.7825V9.97319C8.5796 9.85409 8.55589 9.76491 8.50622 9.70367C8.45486 9.64327 8.38204 9.61279 8.28778 9.61279Z"
    />
    <path
      className="fill-current"
      d="M3.75809 5.74452H4.6454V3.57424L5.67861 0.512695H4.77663L4.22743 2.60396H4.17155L3.59497 0.512695H2.70117L3.75809 3.67189V5.74452Z"
    />
    <path
      className="fill-current"
      d="M6.88067 5.84196C7.23909 5.84196 7.52018 5.74798 7.72508 5.56002C7.92856 5.37121 8.03044 5.11354 8.03044 4.78334V2.78973C8.03044 2.49594 7.9263 2.25407 7.71887 2.06752C7.51002 1.88154 7.24248 1.78784 6.91623 1.78784C6.55781 1.78784 6.27248 1.87646 6.06025 2.05341C5.84859 2.23037 5.74219 2.46771 5.74219 2.76772V4.7681C5.74219 5.09633 5.84576 5.35597 6.05263 5.55071C6.2595 5.74459 6.53608 5.84196 6.88067 5.84196ZM6.55386 2.73836C6.55386 2.65455 6.58405 2.58681 6.64304 2.53375C6.70428 2.48183 6.78189 2.45671 6.87841 2.45671C6.98255 2.45671 7.06694 2.48183 7.13128 2.53375C7.19535 2.58681 7.22724 2.65455 7.22724 2.73836V4.8412C7.22724 4.94421 7.19591 5.02606 7.13241 5.08504C7.06948 5.14431 6.98481 5.17337 6.87841 5.17337C6.77427 5.17337 6.69384 5.14515 6.63739 5.08589C6.5818 5.02803 6.55357 4.94675 6.55357 4.84092V2.73836H6.55386Z"
    />
    <path
      className="fill-current"
      d="M9.22046 5.80104C9.36863 5.80104 9.52074 5.75871 9.67738 5.67743C9.8357 5.59502 9.98726 5.47451 10.1315 5.31731V5.74488H10.9197V1.8855H10.1315V4.81496C10.0581 4.89878 9.97653 4.96764 9.88904 5.02239C9.79986 5.07658 9.72648 5.10367 9.66863 5.10367C9.59469 5.10367 9.54022 5.08335 9.50833 5.0413C9.47587 5.00094 9.45753 4.9349 9.45753 4.84431V1.8855H8.67041V5.11101C8.67041 5.34102 8.71669 5.51289 8.80813 5.62691C8.90127 5.74262 9.0373 5.80104 9.22046 5.80104Z"
    />
  </svg>
);

export const LinkedInIcon = (props) => (
  <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill-current"
      d="M14.7239 0H1.17447C0.525956 0 0 0.51341 0 1.14617V14.8542C0 15.4866 0.525956 16 1.17447 16H14.7239C15.3734 16 15.8993 15.4863 15.8993 14.8542V1.14617C15.8993 0.513088 15.373 0 14.7239 0ZM4.81917 13.394H2.41779V6.16929H4.81917V13.394ZM3.61864 5.18236H3.60256C2.79706 5.18236 2.27593 4.62745 2.27593 3.93422C2.27593 3.22554 2.81314 2.68608 3.6344 2.68608C4.45599 2.68608 4.96136 3.22554 4.97744 3.93422C4.97744 4.62745 4.45567 5.18236 3.61864 5.18236ZM13.4786 13.394H11.0772V9.52801C11.0772 8.55716 10.7298 7.89449 9.86095 7.89449C9.19764 7.89449 8.80261 8.34131 8.62857 8.77301C8.5652 8.9271 8.54976 9.14263 8.54976 9.3588V13.3937H6.14838C6.14838 13.3937 6.1799 6.84611 6.14838 6.16897H8.54976V7.1916C8.86887 6.69943 9.43954 5.99847 10.7144 5.99847C12.2942 5.99847 13.479 7.0314 13.479 9.25071V13.394H13.4786Z"
    />
  </svg>
);

export const FacebookIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.75 40.5">
    <path
      className="fill-current"
      d="M18.75,13.11H12.36V8.91A1.71,1.71,0,0,1,14.14,7h4.51V0L12.44,0C5.55,0,4,5.18,4,8.49v4.63H0v7.15H4V40.5h8.38V20.26H18Z"
    />
  </svg>
);

export const TwitterIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 40.5">
    <path
      className="fill-current"
      d="M50,4.79A20.49,20.49,0,0,1,44.11,6.4,10.25,10.25,0,0,0,48.62.75,20.58,20.58,0,0,1,42.1,3.23a10.26,10.26,0,0,0-17.48,9.32A29.15,29.15,0,0,1,3.48,1.87,10.21,10.21,0,0,0,6.66,15.52,10.25,10.25,0,0,1,2,14.24s0,.09,0,.13a10.24,10.24,0,0,0,8.23,10,10.31,10.31,0,0,1-4.63.17,10.27,10.27,0,0,0,9.58,7.1A20.62,20.62,0,0,1,2.45,36,21.08,21.08,0,0,1,0,35.91,29.1,29.1,0,0,0,15.72,40.5c18.87,0,29.19-15.58,29.19-29.09,0-.44,0-.88,0-1.32A20.79,20.79,0,0,0,50,4.79Z"
    />
  </svg>
);

export const InstagramIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 40.5">
    <path
      className="fill-current"
      d="M20.25,9.72A10.42,10.42,0,1,0,30.67,20.14,10.43,10.43,0,0,0,20.25,9.72Zm0,17.11a6.69,6.69,0,1,1,6.69-6.69A6.7,6.7,0,0,1,20.25,26.83Z"
    />
    <circle className="fill-current" cx="31.06" cy="9.46" r="2.37" />
    <path
      className="fill-current"
      d="M37.23,3.31A11.75,11.75,0,0,0,28.66,0H11.84C4.76,0,0,4.76,0,11.84V28.57a11.81,11.81,0,0,0,3.41,8.72,11.93,11.93,0,0,0,8.52,3.21H28.57a12,12,0,0,0,8.6-3.21,11.72,11.72,0,0,0,3.34-8.64V11.84A11.79,11.79,0,0,0,37.23,3.31Zm-.46,25.34a8.1,8.1,0,0,1-2.21,6,8.35,8.35,0,0,1-6,2.14H11.93A8.29,8.29,0,0,1,6,34.61a8.18,8.18,0,0,1-2.28-6V11.84a8.16,8.16,0,0,1,2.18-6,8.18,8.18,0,0,1,5.93-2.16H28.66a8.12,8.12,0,0,1,5.93,2.21,8.15,8.15,0,0,1,2.18,5.9Z"
    />
  </svg>
);

export const QuoteIcon = (props) => (
  <svg {...props} viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill-current"
      d="M41.386 36V19.2H32.9123C33.1579 13.44 36.4737 8.76 42 6.72V0C30.3333 2.52 24.193 9.96 24.193 21.6V36H41.386ZM17.193 36V19.2H8.7193C8.96491 13.44 12.2807 8.76 17.807 6.72V0C6.14035 2.52 0 9.96 0 21.6V36H17.193Z"
    />
  </svg>
);
